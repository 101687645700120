/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, {
  useContext, useRef, useState, useEffect,
} from 'react';
import {
  StyledTable,
  StyledTableOverlay,
  StyledTableTopOverlay,
  StyledTableWrapper,
} from './Table.styles';
import LeaderboardTable from './LeaderboardTable';
import TaskTable from './TaskTable';
import { ApiContext } from '../../contexts/ApiContext';
import { LangContext } from '../../contexts/LangContext';

const Table = () => {
  const { leaderboards, type, auth } = useContext(ApiContext);
  const { lang } = useContext(LangContext);
  const [scrolling, setScrolling] = useState(false);
  const tableEl = useRef(null);

  const hoverTableRow = (position) => {
    if (window.innerWidth > 670) {
      const row = tableEl.current.querySelectorAll(`.class${position}`);
      row.forEach((cell) => {
        if (cell.id !== `${auth?.username}Id`) {
          cell.style.backgroundColor = '#35363C';
        }
      });
    }
  };

  const hoverTableRowEnd = (position) => {
    if (window.innerWidth > 670) {
      const row = tableEl.current.querySelectorAll(`.class${position}`);
      row.forEach((cell) => {
        if (cell.id !== `${auth?.username}Id`) {
          cell.style.backgroundColor = '#232428';
        }
      });
    }
  };

  const handleScroll = (e) => {
    if (e.target.scrollTop > 70) { setScrolling(true); } else { setScrolling(false); }
  };

  useEffect(() => {
    if (tableEl) {
      handleScroll({ target: tableEl });
    }
  }, [tableEl]);

  useEffect(() => {
    if (leaderboards?.current && auth?.username) {
      const userRow = document.getElementById(`${auth.username}Id`);
      if (userRow) {
        userRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [leaderboards?.current, auth?.username]);

  useEffect(() => {
    if (leaderboards?.current && auth?.username) {
      const userRow = document.getElementById(`${auth.username}Id`);
      if (userRow) {
        userRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [leaderboards?.current, auth?.username]);

  useEffect(() => {
    console.log(leaderboards?.current);
  }, [leaderboards?.current]);

  const tableProps = {
    lang, leaderboards, auth, hoverTableRow, hoverTableRowEnd,
  };

  return (
    <StyledTableWrapper>
      {scrolling && <StyledTableTopOverlay />}
      <StyledTable ref={tableEl} onScroll={handleScroll}>
        {type === 'top50%'
        && <LeaderboardTable {... tableProps} />}
        {type === 'threshold>5'
        && <TaskTable {... tableProps} />}
      </StyledTable>
      <StyledTableOverlay />
    </StyledTableWrapper>
  );
};

export default Table;
