/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledErrorMessage = styled.span`
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.1px;
    color: ${(props) => (props.error === 'followingOtherErrorText' ? '#46DC8E' : '#F53657')};
`;
