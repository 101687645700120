import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LangContext } from '../../contexts/LangContext';
import { ApiContext } from '../../contexts/ApiContext';
import texts from '../../i18n';
import { StyledErrorMessage } from './ErrorMessage.styles';

const ErrorMessage = ({ error, streamerName }) => {
  const { lang } = useContext(LangContext);
  const { auth } = useContext(ApiContext);

  return (
    <>
      { error === 'followingOtherErrorText'
        ? (
          <StyledErrorMessage error={error}>
            <Link
              to={`/${auth.streamer}`}
            >
              {texts[lang][error].replace('{streamer}', auth.streamer)}
            </Link>
          </StyledErrorMessage>
        )
        : (
          <StyledErrorMessage error={error}>
            {texts[lang][error].replace('{streamer}', streamerName)}
          </StyledErrorMessage>
        )}
    </>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.string.isRequired,
  streamerName: PropTypes.string.isRequired,
};

export default ErrorMessage;
