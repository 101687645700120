export default {
  mainHeader: '{streamer}’s Fortnite Leaderboard',
  tableHeader: 'Ranking of leading players on Fortnite',
  ctaText: 'Connect your Epic account & play Fortnite to join the leaderboard',
  rankingHeader: 'Ranking',
  infoHeader: 'Profile Info',
  pointsHeader: 'Points',
  faqText: 'LEARN MORE',
  ctaButtonText: 'CONNECT ACCOUNT',
  ctaConnectedButton: 'ACCOUNT CONNECTED',
  ctaDisconnectText: 'REMOVE MY DATA',
  modalText: 'Once you delete your account from Leaderboard Challenge your progress in the challenge will be deleted. Are you sure you want to leave the Leaderboard Challenge?',
  modelHeadline: 'Remove My Data',
  modalApproveText: 'REMOVE',
  modalDenyText: 'CANCEL',
  not_following: 'Sorry, you need to be following {streamer} for at least 2 days before connecting to their Fortnite Leaderboard',
  not_following_2_days: 'Sorry, you need to be following {streamer} for at least 2 days before connecting to their Fortnite Leaderboard',
  followingOtherErrorText: 'You’re currently connected to {streamer}’s Fortnite Leaderboard. View your standing.',
  cookieText: 'This site uses cookies to provide you with a great user experience. By using StreamElements you accept our ',
  cookieLink: 'use of cookies',
  cookieButton: 'ACCEPT',
  termsLink: 'https://drive.google.com/file/d/1RED-Q2x71kFJKQey0TvtXHfz2UjpL82z/view',
  faqLink: 'https://drive.google.com/file/d/1lZgEay7dARwK2iILZN970-cL5e27_7T6/view',
};
