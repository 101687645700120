export default {
  mainHeader: '{streamer}s Fortnite Leaderboard',
  tableHeader: 'Ranking von führenden Spielern in Fortnite',
  ctaText: 'Verknüpfe dein Epic-Konto und spiele Fortnite, um dem Leaderboard beizutreten',
  rankingHeader: 'Ranking',
  infoHeader: 'Profilinformationen',
  pointsHeader: 'Punkte',
  faqText: 'Mehr erfahren',
  ctaButtonText: 'KONTO VERBINDEN',
  ctaConnectedButton: 'KONTO VERBUNDEN',
  ctaDisconnectText: 'MEINE DATEN ENTFERNEN',
  modalText: 'Sobald du dein Konto aus der LEADERBOARD CHALLENGE entfernst, wird dein Fortschritt in der Herausforderung gelöscht. Möchtest du wirklich aus der LEADERBOARD CHALLENGE aussteigen?',
  modelHeadline: 'Entferne mich aus dem Event',
  modalApproveText: 'Entfernen',
  modalDenyText: 'Abbrechen',
  not_following: 'Sorry, Du musst dem {streamer} für mindestens 2 Tage folgen um in das Fortnite Leaderboard aufgenommen zu werden.',
  not_following_2_days: 'Sorry, Du musst dem {streamer} für mindestens 2 Tage folgen um in das Fortnite Leaderboard aufgenommen zu werden.',
  followingOtherErrorText: 'Du bist gerade mit Supercatkai Fortnite Leaderboard verbunden. Siehe dir deine Platzierung an',
  cookieText: 'Diese Seite benutzt Cookies um dem Nutzer ein schönes Erlebnis zu bieten. Durch die Nutzung von StreamElements akzeptiert man die ',
  cookieLink: 'Nutzung unserer Cookies',
  cookieButton: 'AKZEPTIEREN',
  termsLink: 'https://drive.google.com/file/d/1SjBcH1cvfTFBPP1ze-Tj6H-THq6HEz2N/view',
  faqLink: 'https://drive.google.com/file/d/1mBDD9lU4H6o458bgkl0lZ5cIgpCnYqVc/view',
};
