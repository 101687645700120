/* eslint-disable react/prop-types */
import React from 'react';
import {
  StyledTd,
  StyledTh,
  StyledTaskTableRow,
} from './Table.styles';
import texts from '../../i18n';
import trophy from '../../assets/trophy.svg';
import emptyTrophy from '../../assets/emptyTrophy.svg';

const LeaderboardTable = ({
  lang, leaderboards, auth, hoverTableRow, hoverTableRowEnd,
}) => (
  <>
    <StyledTaskTableRow>
      <StyledTd />
      <StyledTh>{texts[lang].infoHeader}</StyledTh>
    </StyledTaskTableRow>
    {leaderboards?.current && (
    <>
      {leaderboards.current.map(((leader, i) => (
        <StyledTaskTableRow
          key={leader.username}
          highlight={auth?.username === leader?.username}
          id={`${leader.username}Id`}
        >
          <StyledTd
            onMouseEnter={() => hoverTableRow(i)}
            onMouseLeave={() => hoverTableRowEnd(i)}
            className={`class${i}`}
            position={leader.rank}
            id={`${leader.username}Id`}
          >
            <span>
              {leader.points >= 5 ? (
                <>
                  <img style={{ marginRight: '8px' }} src={trophy} alt="" />
                </>
              ) : <img style={{ marginRight: '8px' }} src={emptyTrophy} alt="" />}
            </span>
          </StyledTd>
          <StyledTd
            onMouseEnter={() => hoverTableRow(i)}
            onMouseLeave={() => hoverTableRowEnd(i)}
            className={`class${i}`}
            position={leader.rank}
            id={`${leader.username}Id`}
          >
            <span>{leader.username}</span>
          </StyledTd>
        </StyledTaskTableRow>
      )
      ))}
    </>
    )}
  </>
);

export default LeaderboardTable;
