export default {
  mainHeader: 'Classifica Fortnite di {streamer}',
  tableHeader: 'Rango dei giocatori in classifica su Fortnite',
  ctaText: 'Connetti il tuo account Epic e gioca a Fortnite per partecipare alla classifica',
  rankingHeader: 'Rango',
  infoHeader: 'Informazioni Profilo',
  pointsHeader: 'Punti',
  faqText: 'Scopri di più',
  ctaButtonText: 'CONNETTI ACCOUNT',
  ctaConnectedButton: 'ACCOUNT COLLEGATO',
  ctaDisconnectText: 'RIMUOVI LE MIE INFORMAZIONI',
  modalText: 'Se cancellerai il tuo account dalla CLASSIFICA DELLA SFIDA, perderai tutti i tuoi progressi nella sfida. Vuoi davvero abbandonare la CLASSIFICA DELLA SFIDA?',
  modelHeadline: "Cancellatemi dall'evento",
  modalApproveText: 'Rimuovi',
  modalDenyText: 'Cancella',
  not_following: 'Ci dispiace, devi seguire {streamer} per almeno 2 giorni prima di connetterti alla sua Classifica di Fortnite',
  not_following_2_days: 'Ci dispiace, devi seguire {streamer} per almeno 2 giorni prima di connetterti alla sua Classifica di Fortnite',
  followingOtherErrorText: 'Sei attualmente connesso alla Classifica di Fortnite di Supercatkai. Scopri in che posizione sei.',
  cookieText: "Questo sito utilizza i cookie per dare all'utente la migliore esperienza di gioco. Usando StreamElements accetti ",
  cookieLink: "l'uso dei nostri cookie",
  cookieButton: 'ACCETTA',
  termsLink: 'https://drive.google.com/file/d/160pZOvdHGIv-BnhJTdybk1OYQKi-cCnC/view',
  faqLink: 'https://drive.google.com/file/d/1CYjUrMBnFmgXd-6dOboGmTRc1WQm9c18/view',
};
