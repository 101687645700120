import React, {
  createContext, useState, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import getLink from '../utils/getLink';
import getCookie from '../utils/getCookie';

export const ApiContext = createContext();
const baseLink = getLink('api');

const ApiContextProvider = ({ children }) => {
  const [auth, setAuth] = useState(null);
  const [leaderboards, setLeaderboards] = useState(null);
  const [conditions, setconditions] = useState(null);
  const [type, setType] = useState(null);
  const [jwt, setJwt] = useState('');
  const [authLoading, setAuthLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [disconnect, setDisconnect] = useState(false);
  const { streamer } = useParams();

  const apiFetch = async (url, action, token) => {
    try {
      let fetched;
      if (token) {
        fetched = await fetch(url, {
          method: action,
          headers: new Headers({
            Authorization: `Bearer ${token}`,
          }),
        });
      } else {
        fetched = await fetch(url, {
          method: action,
        });
      }
      const response = await fetched.json();
      if (response.error) {
        console.log(response.error);
        return null;
      }
      return response;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const getAuth = async () => {
    setAuthLoading(true);
    const authResponse = await apiFetch(`${baseLink}/v1/me`, 'get', jwt);
    setAuth(authResponse);
    setAuthLoading(false);
  };

  const deleteUser = async () => {
    setAuthLoading(true);
    await apiFetch(`${baseLink}/v1/user`, 'delete', jwt);
    document.cookie = 'epic-se=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    setAuth(null);
    setAuthLoading(false);
  };

  const getLeaderboards = async (streamerusername) => {
    setApiLoading(true);
    const lbs = await apiFetch(`${baseLink}/v1/cap/streamers/${streamerusername}`, 'get');
    setconditions(lbs?.leaderboardDates[0].condition);
    setType(lbs?.leaderboardDates[0].rewardCondition);
    setLeaderboards(lbs);
    setApiLoading(false);
  };

  const getLeaderboard = async (leaderboardId) => {
    setApiLoading(true);
    const lb = await apiFetch(`${baseLink}/v1/cap/leaderboards/${leaderboardId}`, 'get');
    const newconditions = leaderboards?.leaderboardDates?.find((date) => date.id === leaderboardId);
    setconditions(newconditions?.condition);
    setType(newconditions?.rewardCondition);
    setLeaderboards({ ...leaderboards, ...{ current: lb.data } });
    setApiLoading(false);
  };

  const initLeaderboard = async () => {
    setJwt(getCookie('epic-se'));
    if (jwt) {
      await getAuth();
      if (disconnect) {
        await deleteUser();
        document.cookie = 'epic-se=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location = 'https://fortnite.com';
      }
    }
    getLeaderboards(streamer);
  };

  useEffect(() => {
    initLeaderboard();
  }, [jwt, disconnect, streamer]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    setDisconnect(query.get('disconnect'));
  }, []);

  const state = {
    auth,
    leaderboards,
    authLoading,
    apiLoading,
    conditions,
    type,
    deleteUser,
    getLeaderboard,
  };

  return (
    <ApiContext.Provider
      value={state}
    >
      {!disconnect && children}
    </ApiContext.Provider>
  );
};

ApiContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiContextProvider;
