/* eslint-disable react/prop-types */
import React from 'react';
import {
  StyledTd,
  StyledTh,
  StyledPositionTd,
  StyledTableRow,
} from './Table.styles';
import texts from '../../i18n';
import star from '../../assets/star.svg';

const LeaderboardTable = ({
  lang, leaderboards, auth, hoverTableRow, hoverTableRowEnd,
}) => (
  <>
    <StyledTableRow>
      <StyledTh>{texts[lang].rankingHeader}</StyledTh>
      <StyledTh>{texts[lang].infoHeader}</StyledTh>
      <StyledTh>{texts[lang].pointsHeader}</StyledTh>
    </StyledTableRow>
    {leaderboards?.current && (
    <>
      {leaderboards.current.map(((leader, i) => (
        <StyledTableRow
          key={Math.random()}
          highlight={auth?.username === leader?.username}
          id={`${leader.username}Id`}
        >
          <StyledPositionTd
            onMouseEnter={() => hoverTableRow(i)}
            onMouseLeave={() => hoverTableRowEnd(i)}
            className={`class${i}`}
            position={leader.rank}
            id={`${leader.username}Id`}
          >
            <span>
              {leader.rank === 1 ? (
                <>
                  <img style={{ marginRight: '8px' }} src={star} alt="" />
                  <span>{leader.rank}</span>
                </>
              ) : leader.rank}
            </span>
          </StyledPositionTd>
          <StyledTd
            onMouseEnter={() => hoverTableRow(i)}
            onMouseLeave={() => hoverTableRowEnd(i)}
            className={`class${i}`}
            position={leader.rank}
            id={`${leader.username}Id`}
          >
            <span>{leader.username}</span>
          </StyledTd>
          <StyledTd
            onMouseEnter={() => hoverTableRow(i)}
            onMouseLeave={() => hoverTableRowEnd(i)}
            className={`class${i}`}
            position={leader.rank}
            id={`${leader.username}Id`}
          >
            <span>{leader.points}</span>
          </StyledTd>
        </StyledTableRow>
      )
      ))}
    </>
    )}
  </>
);

export default LeaderboardTable;
