import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Leaderboard from './views/Leaderboard';
import LangContextProvider from './contexts/LangContext';

function App() {
  return (
    <LangContextProvider>
      <Router>
        <Route path="/:streamer">
          <Leaderboard />
        </Route>
      </Router>
    </LangContextProvider>
  );
}

export default App;
