import en from './en';
import ru from './ru';
import de from './de';
import it from './it';

export default {
  en,
  ru,
  de,
  it,
};
