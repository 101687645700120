const config = {
  userApiUrl: 'https://api.streamelements.com/kappa/v2/channels/{streamerName}',
  listApiUrl: 'https://overwolf.streamelements.com/leaderboard?channel={streamerId}',
  backgroundImg: '',
  heading: '{streamer} Leaderboard',
  subheading: 'Ranking of leading players',
  tableRankHeader: 'Ranking',
  tableNameHeader: 'Profile Info',
  tablePointsHeader: 'Points',
  ctaHeader: 'Lorem ipsum',
  cta: 'CONNECT',
  checkboxText: 'Lorem ipsum',
};

export default config;
