import React, { useContext } from 'react';
import { LangContext } from '../contexts/LangContext';
import texts from '../i18n';

const CtaHeadline = () => {
  const { lang } = useContext(LangContext);

  return (
    <h5>{texts[lang].ctaText}</h5>
  );
};

export default CtaHeadline;
