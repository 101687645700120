import React, { useContext, useState, useEffect } from 'react';
import { LangContext } from '../../contexts/LangContext';
import { StyledCookieBanner, StyledAccept } from './CookieBanner.styles';
import texts from '../../i18n';
import getCookie from '../../utils/getCookie';

const CtaHeadline = () => {
  const { lang } = useContext(LangContext);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!getCookie('accepted-cookies')) {
      setShowBanner(true);
    }
  }, []);

  const acceptBanner = () => {
    document.cookie = 'accepted-cookies=true';
    setShowBanner(false);
  };

  return (
    showBanner
    && (
    <StyledCookieBanner>
      <span>
        {texts[lang].cookieText}
        <a href="https://streamelements.com/cookies" target="_blank" rel="noreferrer">{texts[lang].cookieLink}</a>
        .
      </span>
      <StyledAccept
        onClick={acceptBanner}
      >
        {texts[lang].cookieButton}
      </StyledAccept>
    </StyledCookieBanner>
    )
  );
};

export default CtaHeadline;
