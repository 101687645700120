import styled from 'styled-components';

export const StyledContent = styled.main`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 1em;
  justify-content: center;
  padding: 10px;
  width: 100%;
  max-width: 1300px;
  margin-bottom: 20px;

  @media only screen and (max-width: 1300px)  {
    max-width: none;
  }

  @media only screen and (max-width: 880px)  {
    padding: 0 10px;
    grid-template-columns: 90%;
    grid-gap: 27px;
  }
  
  @media only screen and (max-width: 670px)  {
    padding: 0 16px;
  }
`;

export const StyledFooter = styled.footer`  
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;

  a{
    margin-left: 19px;
    color: #4678FF;
  }
  
  @media only screen and (max-width: 980px)  {
    flex-direction: column;
    align-items: center;
    position: static;
  }
`;

export const StyledFooterSpan = styled.span` 

  @media only screen and (max-width: 980px)  {
    margin: 12px 0 20px 0;
  }
`;

export const StyledCtaSection = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen and (max-width: 980px)  {
    max-width: none;
  }

  @media only screen and (max-width: 670px)  {
      text-align: center;
  }

`;

export const StyledTableSection = styled.section`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 980px)  {
    max-width: none;
    margin-bottom: 42px;
  }

`;

export const StyledLogo = styled.img`
  margin: 5vh 0 2vh 0;
  width: 265px;
  max-width: 265px;
  height: auto;

  @media only screen and (max-width: 880px)  {
    margin: 5vh 0 2vh 25px;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
`;

export const StyledWrapperInsert = styled.div`
  height: 100%;
`;
