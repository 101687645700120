import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/it';
import 'moment/locale/de';
import {
  StyledHeading, StyledHeaderImg, StyledHeader, StyledSubHeading, StyledSelect, StyledSelectWrapper,
} from './Header.styles';
import texts from '../../i18n';
import calendar from '../../assets/calendar.svg';
import { ApiContext } from '../../contexts/ApiContext';
import { LangContext } from '../../contexts/LangContext';

const Header = ({ streamerName, streamerImg }) => {
  const { lang } = useContext(LangContext);

  const { leaderboards, apiLoading, getLeaderboard } = useContext(ApiContext);
  const [dateList, setDatelist] = useState([]);
  const [currentDate, setCurrentDate] = useState(dateList[0]?.id);

  useEffect(() => {
    let dates = [];
    if (leaderboards?.leaderboardDates) {
      dates = leaderboards.leaderboardDates.map((date) => ({
        id: date.id,
        text: `${moment(date.from).locale(lang).format('MMM DD')}`,
      }));
    }
    setDatelist(dates);
  }, [leaderboards?.leaderboardDates, lang]);

  const changeLeaderboard = (value) => {
    setCurrentDate(value);
    getLeaderboard(value);
  };

  return (
    <StyledHeader>
      <StyledHeading>
        <StyledHeaderImg src={streamerImg} />
        <h3>{texts[lang].mainHeader.replace('{streamer}', streamerName)}</h3>
      </StyledHeading>
      <StyledSubHeading>
        <p>{texts[lang].tableHeader.replace('{streamer}', streamerName)}</p>
        <div>
          <StyledSelectWrapper>
            <img src={calendar} alt="" />
            {leaderboards && (
            <StyledSelect
              disabled={apiLoading}
              name="dates"
              id="dates"
              value={currentDate}
              onChange={(e) => changeLeaderboard(e.target.value)}
            >
              {dateList?.map((date) => (
                <option value={date.id} key={date.id}>
                  {date.text}
                </option>
              ))}
            </StyledSelect>
            )}
          </StyledSelectWrapper>
        </div>
      </StyledSubHeading>
    </StyledHeader>
  );
};

Header.propTypes = {
  streamerImg: PropTypes.string.isRequired,
  streamerName: PropTypes.string.isRequired,

};

export default Header;
