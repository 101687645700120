import styled from 'styled-components';

export const StyledTable = styled.div`
    min-width: calc(100% + 20px);;
    padding: 39px 75px 20px 42px;
    display: flex;
    height: 654px;
    max-height: 654px;
    overflow: auto;
    position: relative;
    flex-direction: column;

    @media only screen and (max-width: 670px)  {
        padding: 39px 20px 0 0;
    }

`;

export const StyledTd = styled.div`
    padding: 10px 0 10px 12px;
    font-weight: ${(props) => (props.position === 0 ? '900' : '700')};
    

    span{
        font-size: 14px;
        font-weight: ${(props) => (props.position === 0 ? '900' : '700')};
    }
`;

export const StyledTableRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-column-gap: 0.5em;
    margin-bottom: 18px;
    background-color: ${(props) => (props.highlight ? '#4678FF' : 'transparent')};
`;

export const StyledTaskTableRow = styled(StyledTableRow)`
    grid-template-columns: 1fr 10fr;
`;

export const StyledPositionTd = styled(StyledTd)`
    padding-left: ${(props) => (props.position === 1 ? '12px' : '33px')};
    font-weight: normal;

    span{
        display: flex;
        align-items: center;
        font-weight: ${(props) => (props.position === 1 ? '900' : '700')};
    }
`;

export const StyledTh = styled(StyledTd)`
    font-weight: 700;
    padding: 0 0 15px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.87);    
`;

export const StyledTableOverlay = styled.div`
    background: linear-gradient(180deg, rgba(35, 36, 40, 0.7) 5.67%, rgba(35, 36, 40, 0.07) 39.09%, rgba(35, 36, 40, 0) 106.17%);    
    position: absolute;
    bottom: 0;
    pointer-events: none;
    z-index: 3;
    width: 100%;
    height: 35%;
    transform: rotate(-180deg);
`;

export const StyledTableTopOverlay = styled.div`
    background: linear-gradient(180deg, rgba(35, 36, 40, 0.7) 5.67%, rgba(35, 36, 40, 0.07) 39.09%, rgba(35, 36, 40, 0) 106.17%);    
    position: absolute;
    top: 0px;
    pointer-events: none;
    z-index: 3;
    width: 100%;
    height: 35%;
`;

export const StyledTableWrapper = styled.section`
    background-color: #232428;
    position: relative;
    display: flex;
    height: 654px;
    max-height: 654px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    @media only screen and (max-width: 980px)  {
        margin-right: 0;
        width: 100%;
        max-width: 100%;
    }
`;
