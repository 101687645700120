export default {
  mainHeader: 'Таблица лидеров Fortnite: {streamer}',
  tableHeader: 'Рейтинг лидирующих игроков в Fortnite',
  ctaText: 'Подключите аккаунт Epic Games и играйте в Fortnite, чтобы попасть в таблицу лидеров',
  rankingHeader: 'Позиция',
  infoHeader: 'Профиль',
  pointsHeader: 'Очки',
  faqText: 'Узнать больше',
  ctaButtonText: 'ПОДКЛЮЧИТЬ АККАУНТ',
  ctaConnectedButton: 'АККАУНТ ПОДКЛЮЧЕН',
  ctaDisconnectText: 'УДАЛИТЬ МОИ ДАННЫЕ',
  modalText: 'Если вы удалите вашу учётную запись в «Leaderboard Challenge», все ваши достижения будут утеряны безвозвратно. Вы уверены, что хотите покинуть «Leaderboard Challenge»?',
  modelHeadline: 'Исключите меня из соревнования',
  modalApproveText: 'Удалить',
  modalDenyText: 'Отменить',
  not_following: 'Извините, вы должны быть фолловером {streamer} минимум 2 дня до подключения к таблице лидеров Fortnite',
  not_following_2_days: 'Извините, вы должны быть фолловером {streamer} минимум 2 дня до подключения к таблице лидеров Fortnite',
  followingOtherErrorText: 'Вы сейчас соединены с Fortnite таблицей лидеров Supercatkai. Посмотрите вашу позицию.',
  cookieText: 'Этот сайт использует файлы cookie для максимального комфорта и функционала для пользователей. При использовании StreamElements вы соглашаетесь ',
  cookieLink: 'на использование файлов cookie',
  cookieButton: 'Принять',
  termsLink: 'https://drive.google.com/file/d/190q1NdX_sren22o4rSOCiDixDHJdv1NV/view',
  faqLink: 'https://drive.google.com/file/d/1uXrm9ctp7e1Ww9gLnMbOjE-F81w6odUy/view',
};
