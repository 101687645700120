import React, { useState, useEffect, useContext } from 'react';
import { track } from '@streamelements/alchemy';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  StyledCta,
  StyledDownloadLink,
  StyledCheckboxWrapper,
  StyledLogoutButton,
  StyledCtaWrapper,
  StyledFaqLink,
  StyledTask,
  StyledPopupOverlay,
  StyledPopup,
  StyledPopupWrapper,
  StyledPopupActions,
  StyledRemoveBtn,
  StyledCancelBtn,
  StyledCloseBtn,
} from './Cta.styles';
import getLink from '../../utils/getLink';
import { ApiContext } from '../../contexts/ApiContext';
import ctaImg from '../../assets/fortnite-leaderboard-character.png';
import ctaLogo from '../../assets/fortnite-leaderboard-logo.png';
import modalClose from '../../assets/close.svg';
import texts from '../../i18n';
import isTouchDevice from '../../utils/isTouchDevice';
import { LangContext } from '../../contexts/LangContext';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const Cta = ({ streamerId, streamerName }) => {
  const { lang } = useContext(LangContext);
  const { streamer } = useParams();
  const [ctaHref, setCtaHref] = useState('');
  const {
    auth, authLoading, deleteUser, conditions,
  } = useContext(ApiContext);
  const [buttonText, setButtonText] = useState(texts[lang].ctaConnectedButton);
  const [error, setError] = useState('');
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (!authLoading) {
      const baseLink = getLink('LP');
      setCtaHref(`${baseLink}?streamer=${streamer}&channel=${streamerId}&campaign=cap&action=login-participate&lang=${lang}`);
    }
  }, [authLoading, lang]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const queryError = query.get('error');
    if (queryError) {
      setError(queryError);
    } else if (auth?.streamer && auth?.streamer !== streamer) {
      setError('followingOtherErrorText');
    } else {
      setError('');
    }
  }, [streamerName, auth]);

  useEffect(() => {
    if (isTouchDevice()) {
      setButtonText(texts[lang].ctaDisconnectText);
    }
    setButtonText(texts[lang].ctaConnectedButton);
  }, [lang]);

  const trackClick = (name) => {
    track({
      name,
      event: name,
      source: 'leaderboard',
      feature: 'leaderboard',
      product: 'sponsorship',
      fields: [['partnershipid', 'leaderboard-test']],
    });
  };

  const deleteFn = async () => {
    trackClick('remove_my_data_confirm');
    await deleteUser();
    setModal(false);
  };

  const deleteClick = () => {
    setModal(true);
    trackClick('remove_my_data_click');
  };

  return (
    <StyledCtaWrapper>
      <StyledCta>
        <img src={ctaImg} alt="" />
        {auth?.streamer === streamer
          ? (
            <StyledLogoutButton
              onMouseEnter={() => { setButtonText(texts[lang].ctaDisconnectText); }}
              onMouseLeave={() => {
                if (!isTouchDevice()) { setButtonText(texts[lang].ctaConnectedButton); }
              }}
              onClick={deleteClick}
            >
              {buttonText}
            </StyledLogoutButton>
          )
          : (
            <StyledDownloadLink
              disabled={auth && auth?.streamer !== streamer}
              href={ctaHref}
              onClick={() => trackClick('connect_account')}
            >
              {texts[lang].ctaButtonText}
            </StyledDownloadLink>
          )}
        {error ? <ErrorMessage error={error} streamerName={streamerName} /> : <img src={ctaLogo} alt="" />}
      </StyledCta>
      <StyledCheckboxWrapper>
        <StyledTask>
          {conditions
          && conditions[lang]
          && conditions[lang].map((condition) => (
            <li key={condition}>{condition}</li>
          ))}
        </StyledTask>
        <StyledFaqLink
          href={texts[lang].faqLink}
          onClick={() => trackClick('faq_click')}
        >
          {texts[lang].faqText}
        </StyledFaqLink>
      </StyledCheckboxWrapper>
      {modal
      && (
      <>
        <StyledPopupOverlay />
        <StyledPopupWrapper>
          <StyledPopup>
            <StyledCloseBtn onClick={() => setModal(false)} type="button">
              <img
                src={modalClose}
                alt="close"
              />
            </StyledCloseBtn>
            <h5>{texts[lang].modelHeadline}</h5>
            <p>{texts[lang].modalText.replace('{streamer}', streamerName)}</p>
            <StyledPopupActions>
              <StyledCancelBtn onClick={() => setModal(false)}>
                {texts[lang].modalDenyText}
              </StyledCancelBtn>
              <StyledRemoveBtn
                disabled={authLoading}
                onClick={deleteFn}
              >
                {texts[lang].modalApproveText}
              </StyledRemoveBtn>
            </StyledPopupActions>
          </StyledPopup>
        </StyledPopupWrapper>
      </>
      )}
    </StyledCtaWrapper>
  );
};

Cta.propTypes = {
  streamerId: PropTypes.string.isRequired,
  streamerName: PropTypes.string.isRequired,
};

export default Cta;
