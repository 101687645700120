import styled from 'styled-components';

export const StyledCookieBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 10px;
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: #232428;

  a{
    text-decoration: none;
    color: #4678FF;
  }
`;

export const StyledAccept = styled.button`
    color: #4678FF;
    background-color: transparent;
    border: 1px solid #4678FF;
    font-weight: bold;
    font-size: 14px;
    height: 40px;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 1.25px;
    padding: 10px 16px;
    border-radius: 20px;
    line-height: 20px;
    margin-left: 36px;
`;
