/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { init, track } from '@streamelements/alchemy';
import Table from '../components/Table/Table';
import Header from '../components/Header/Header';
import Cta from '../components/Cta/Cta';
import CtaHeadline from '../components/CtaHeadline';
import LangDropdown from '../components/LangDropdown/LangDropdown';
import {
  StyledFooter,
  StyledFooterSpan,
  StyledContent,
  StyledLogo,
  StyledWrapper,
  StyledWrapperInsert,
} from '../styles/globals';
import config from '../config/config';
import ApiContextProvider from '../contexts/ApiContext';
import { LangContext } from '../contexts/LangContext';
import logo from '../assets/fortnite-leaderboard-logo.png';
import CookieBanner from '../components/CookieBanner/CookieBanner';
import texts from '../i18n';

const App = () => {
  const [streamerObj, setStreamerObj] = useState(null);
  const { streamer } = useParams();
  const { lang } = useContext(LangContext);

  const getStreamer = async () => {
    if (streamer) {
      try {
        const fetchedStreamer = await fetch(config.userApiUrl.replace('{streamerName}', streamer));
        const streamerResponse = await fetchedStreamer.json();
        if (streamerResponse.error) {
          window.location = 'https://streamelements.com/';
        } else {
          setStreamerObj(streamerResponse);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getStreamer();
  }, [streamer]);

  useEffect(() => {
    document.title = `${streamerObj?.displayName}'s leaderboard`;
  }, [streamerObj]);

  useEffect(() => {
    init();
    track({
      name: 'leaderboard_page_load',
      event: 'leaderboard_page_load',
      source: 'landing_page',
      feature: 'landing_page',
      product: 'sponsorship',
      fields: [['partnershipid', 'leaderboard-test']],
    });
  }, []);

  return (
    <ApiContextProvider>
      <StyledWrapper>
        <StyledWrapperInsert>
          <StyledLogo src={logo} alt="" />
          <LangDropdown />
          {streamerObj && (
          <StyledContent>
            <Header streamerName={streamerObj?.displayName} streamerImg={streamerObj?.avatar} />
            <CtaHeadline />
            <Table streamerId={streamerObj?._id} />
            <Cta streamerId={streamerObj?._id} streamerName={streamerObj?.displayName} />
          </StyledContent>
          )}
          <StyledFooter>
            <span>Created with ❤️ by StreamElements © All rights reserved</span>
            <StyledFooterSpan>
              <a href="https://streamelements.com/contact">Contact us</a>
              <a href={texts[lang].termsLink}>Terms & conditions</a>
              <a href="https://streamelements.com/privacy">Privacy policy</a>
            </StyledFooterSpan>
          </StyledFooter>
        </StyledWrapperInsert>
      </StyledWrapper>
      <CookieBanner />
    </ApiContextProvider>
  );
};

export default App;
